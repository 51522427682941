export const enum PromptSettingsLiveStatus {
  COMPLETED = 'COMPLETED',
  UPCOMING = 'UPCOMING',
  LIVE = 'LIVE',
}

export enum PromptTypePicker {
  STANDARD = 'STANDARD',
  LIVE = 'LIVE',
}

export const PROMPT_REPLIES_PAGE_SIZE = 6;
